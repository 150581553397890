<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="忘记密码" left-arrow @click-left="$router.back()" />
    </van-sticky>

    <van-cell-group>
      <van-field
        @blur="checkphone"
        label="个人手机号"
        size="large"
        type="tel"
        maxlength="11"
        v-model="para.phone"
        placeholder="请输个人手机号"
        :error-message="paraErr.phone"
      >
      </van-field>
      <van-field
        @blur="checksmsCode"
        label="验证码"
        maxlength="6"
        size="large"
        type="digit"
        v-model="para.smsCode"
        placeholder="请输手机验证码"
        :error-message="paraErr.smsCode"
      >
        <template #button>
          <van-button
            size="small"
            type="info"
            @click="sendSMSCode"
            :disabled="disabledSend"
            :text="sendText"
          ></van-button>
        </template>
      </van-field>
      <van-field
        @blur="checkpassWord"
        label="密码"
        size="large"
        v-model="para.passWord"
        placeholder="请输入新密码"
        maxlength="20"
        type="password"
        :error-message="paraErr.passWord"
      >
      </van-field>
    </van-cell-group>
    <div class="btn-box">
      <van-button
        type="info"
        size="large"
        round
        block
        @click="SetPassWord"
        :disabled="disabledSubmit"
        >确认找回</van-button
      >
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { resetPassWordSMSCode, resetPassWordByPhone } from "@/api/user";

export default {
  name: "findPassWord",
  data() {
    return {
      sendText: "发送验证码",
      disabledSend: false,
      disabledSubmit: false,
      time: 120,
      para: {
        phone: "",
        smsCode: "",
        passWord: "",
      },
      paraErr: {
        phone: "",
        smsCode: "",
        passWord: "",
      },
    };
  },
  created() {
    if (this.$route.query.username) {
      this.para.phone = this.$route.query.username;
    }
  },
  methods: {
    checkphone() {
      if (!this.para.phone) {
        this.paraErr.phone = "个人手机号不能为空";
        return false;
      }
      if (!/^1[3-9]\d{9}$/.test(this.para.phone)) {
        this.paraErr.phone = "手机号格式不正确";
        return false;
      }
      this.paraErr.phone = "";
      return true;
    },

    checksmsCode() {
      if (!this.para.smsCode) {
        this.paraErr.smsCode = "验证码不能为空";
        return false;
      }
      this.paraErr.smsCode = "";
      return true;
    },
    checkpassWord() {
      if (!this.para.passWord) {
        this.paraErr.passWord = "新密码不能为空";
        return false;
      }
      if (this.para.passWord.length < 6) {
        this.paraErr.passWord = "密码长度需要大于等于6位";
        return false;
      }
      this.paraErr.passWord = "";
      return true;
    },
    async SetPassWord() {
      this.disabledSubmit = true;
      if (this.checkphone() && this.checksmsCode() && this.checkpassWord()) {
        const data = await resetPassWordByPhone(this.para);
        if (data.data.success) {
          Toast("密码找回成功");
          this.$router.replace({
            path: "/login",
            query: { username: this.para.phone },
          });
        } else {
          Toast(data.data.errorMessage);
        }
      }
      this.disabledSubmit = false;
      
    },
    async sendSMSCode() {
      if (this.checkphone()) {
        this.disabledSend = true;
        const send = await resetPassWordSMSCode(this.para.phone);
        if (send.data.success) {
          Toast("短信已发送");
          this.time = 90;
          let timeInter = setInterval(() => {
            this.time--;
            this.sendText = "已发送" + this.time + "s";
            if (this.time === 0) {
              this.sendText = "发送验证码";
              this.disabledSend = false;
              clearInterval(timeInter);
            }
          }, 1000);
        } else {
          Toast(send.data.errorMessage);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.btn-box {
  margin: 20px;
}
</style>